import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { CommonProvider } from '../common/common.provider';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  private loggedIn: boolean = false;
  loggedInfo:any = [];

  constructor(
    private router: Router,
	  private common: CommonProvider
  ) {
    this.loggedInfo = this.common.getUserInfo();
    if(!this.common.validateField(this.loggedInfo))
      this.loggedIn = true;
    else
      this.router.navigate(['/login']);

  }

  public canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    this.loggedInfo = this.common.getUserInfo();
    this.loggedIn = false;
    if(!this.common.validateField(this.loggedInfo))
      this.loggedIn = true;
    if (!this.loggedIn) {
      return this.router.navigate(['login']);
    }

    return this.loggedIn;
  }

  public getGuardAuthentication(): boolean {
    this.loggedInfo = this.common.getUserInfo();
    this.loggedIn = false;
    if(!this.common.validateField(this.loggedInfo))
      this.loggedIn = true;
    return this.loggedIn;
  }
}
