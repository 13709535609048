import { Injectable, ViewChildren, QueryList } from '@angular/core';
import { Platform, AlertController, LoadingController, ToastController, ModalController, NavController } from '@ionic/angular';
//import { InAppBrowser, InAppBrowserOptions } from '@ionic-native/in-app-browser/ngx';
import { InAppBrowser, InAppBrowserOptions } from '@awesome-cordova-plugins/in-app-browser/ngx';
//import { Router } from '@angular/router';
import config from './config';
import { Device } from '@awesome-cordova-plugins/device/ngx';
import { Network } from '@ionic-native/network/ngx';
//import { App } from '@capacitor/app';
import { FirebaseX } from '@awesome-cordova-plugins/firebase-x/ngx';

@Injectable({
  providedIn: 'root'
})
export class CommonProvider{
	public APPSESSION = 'ROOFANDFLOOR';
	private loading:any;
	private toast:any;
	private modal;
	private backInit;
	public shortMonths;
	public fullMonths;
	pdpPropertyDatas:any = [];
	deviceInfo:any = [];
	appVersion:any;

	inappOptions : InAppBrowserOptions = {
		location : 'yes',
		hidden : 'no',
		clearcache : 'yes',
		clearsessioncache : 'yes',
		zoom : 'no',//Android only ,shows browser zoom controls 
		hardwareback : 'yes',
		mediaPlaybackRequiresUserAction : 'yes',
		shouldPauseOnSuspend : 'no', //Android only 
		closebuttoncaption : 'Close', //iOS only
		disallowoverscroll : 'no', //iOS only 
		toolbar : 'yes', //iOS only 
		enableViewportScale : 'no', //iOS only 
		allowInlineMediaPlayback : 'no',//iOS only 
		presentationstyle : 'fullscreen',//iOS only 
		fullscreen : 'yes',//Windows only,
		hidenavigationbuttons:'yes',
		hideurlbar:'yes',
		toolbarcolor:'#1F4C6B',
		lefttoright:'no',
		closebuttoncolor:'#ffffff',
		toolbarposition:'top',
		hidespinner:'yes',
	};


	constructor(public alertCtrl: AlertController, public loadingCtrl: LoadingController, private toastCtrl: ToastController, public platform: Platform, public device: Device, private modalCtrl: ModalController, public navCtrl: NavController, private iab: InAppBrowser, private network: Network, private fcm: FirebaseX){
		this.backInit = false;
		this.shortMonths = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
		this.fullMonths = ['January','February','March','April','May','June','July','August','September','October','November','December'];
	}
	lastTimeBackPress = 0;
	backbuttonSubscription: any;

	validateField(value) {
		return (value=='' || value=="undefined" || value==undefined || value==0 || value==null || (typeof value!='object' && typeof value!='number' && value.trim() == ''))?true:false;
	}

	validateEmail(value) {
		return (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value))?false:true;
	}

	showToast(message:string,duration?:number) {
		this.hideToast();
		this.toast = this.toastCtrl.create({
			message: message,
			duration: (duration != null?duration:5000),
			position: 'bottom',
			//color:themeColor,
			cssClass:"toastAfterHeader"
		});
		this.toast.then(res=>{
			res.present();
		});
	}

	hideToast() {
		if(this.toast!=undefined)
			this.toast.then(res=>{ res.dismiss(); });
	}

	async confirmation(header: any,message: any,cancelText: any,okText: any): Promise<any> {
		return new Promise(async (resolve) => {
			const alert = this.modal = await this.alertCtrl.create({
				header: header,
				message: message,
				backdropDismiss:true,
				cssClass: 'confirm-popup alert-confirmation',
				buttons: [
					{
						text: okText,
						cssClass: 'alert-success',
						handler: (ok) => {
							resolve(1);
							this.modal = '';
						}
					},
					{
						text: cancelText,
						role: 'cancel',
						cssClass: 'alert-danger',
						handler: (cancel) => {
							resolve(0);
							this.modal = '';
						}
					}
				]
			});
			alert.present();
		});
	}

	async alertCallback(alertTitle,message): Promise<any> {
		return new Promise(async (resolve) => {
			const alert = this.modal = await this.alertCtrl.create({
				header: alertTitle,
				message: message,
				backdropDismiss:true,
				cssClass: 'alert',
				buttons: [
					{
						text: 'OK',
						cssClass: 'alert-success',
						handler: (ok) => {
							resolve(1);
							this.modal = '';
						}
					}
				]
			});
			alert.present();
		});
	}

	async showAlert(alertTitle, message) {
		message = message=='SERVER_ERROR'?config.SERVER_ERROR:message;
		let alert = this.modal = await this.alertCtrl.create({
			header: alertTitle,
			subHeader:'',
			message: message,
			backdropDismiss:true,
			buttons: [{text:'OK',cssClass: 'alert-success'}]
		});
		alert.present();
	}

	showLoader() {
		this.hideLoader();
		return new Promise((resolve,reject)=>{
			this.loading = this.loadingCtrl.create({
				showBackdrop:true,
				spinner:null,
				message:"<span class='loader-img'></span> Please wait...",
				translucent:true
			});
			this.loading.then(res=>{ res.present(); });
		});
	}

	hideLoader() {
		if(this.loading!=undefined)
			this.loading.then(res=>{ res.dismiss(); });
	}

	/*formatRupee(amount,currency) {
		if(typeof amount=='string')
			amount = parseFloat(amount);
		if(currency==2)
			return '₹'+amount.toFixed(2).replace(/(\d)(?=(\d{2})+\d\.)/g, '$1,').replace('.00','');
		if(currency==1)
				return '₹'+amount.toFixed(2).replace(/(\d)(?=(\d{2})+\d\.)/g, '$1,');
		if(currency==-1)
			return amount.toFixed(2).replace(/(\d)(?=(\d{2})+\d\.)/g, '$1,');
		return amount.toFixed(2).replace(/(\d)(?=(\d{2})+\d\.)/g, '$1,').replace('.00','');
	}*/

	formatRupee(value,currency) {
		let val = Math.abs(value);
		let returnVal:string = '';
		if (val >= 10000000)
			returnVal = (val / 10000000).toFixed(2)+' Cr';
		else if (val >= 100000)
			returnVal = (val / 100000).toFixed(2)+' L';
		else if (val >= 1000)
			returnVal = (val / 1000).toFixed(2)+' K';
		if(this.validateField(returnVal))
			returnVal = '0';
		return (!this.validateField(returnVal) || currency==3)?'₹'+returnVal:'';
	}

	shuffleArray(array) {
		for (let i = array.length - 1; i > 0; i--) {
			const j = Math.floor(Math.random() * (i + 1));
			[array[i], array[j]] = [array[j], array[i]];
		}
		return array;
	}

	getFormattedDate(type,chosedDate) {
		let choosedDate = new Date(chosedDate);
		let chsedMonth = choosedDate.getMonth();
		//let chsdMonth = (chsedMonth>9)?chsedMonth:'0'+chsedMonth;
		let chsdMonth = chsedMonth;
		let chsdDate = (choosedDate.getDate()>9)?choosedDate.getDate():'0'+choosedDate.getDate();
		switch(type) {
			case 1:
				return this.shortMonths[chsdMonth]+' '+chsdDate+', '+choosedDate.getFullYear();
			break;
			case 2:
				return this.fullMonths[chsdMonth]+' '+choosedDate.getFullYear();
			break;
			case 3:
				return this.shortMonths[chsdMonth]+' '+choosedDate.getFullYear();
				//return this.shortMonths[chsdMonth]+' '+choosedDate.getFullYear().toString().substr(-2);
			break;
		}
	  }

	setUserInfo(data) {
		localStorage.setItem(this.APPSESSION, JSON.stringify(data));
		localStorage.setItem(this.APPSESSION+'_USERID',data.id);
	}

	getUserInfo() {
		let userInfo;
		
		if(!this.validateField(localStorage.getItem(this.APPSESSION))) {
			let sessData;
			sessData = localStorage.getItem(this.APPSESSION);
			userInfo = JSON.parse(sessData);
		}
		return userInfo;
	}

	getUserName() {
		let uData = this.getUserInfo();
		if(!this.validateField(uData))
			return uData.name;
		return '';
	}

	setSession(name,value) {
		localStorage.setItem(this.APPSESSION+'_'+name,value);
	}

	getSession(name) {
		return localStorage.getItem(this.APPSESSION+'_'+name);
	}

	clearSession(name) {
		localStorage.removeItem(this.APPSESSION+'_'+name);
	}

	getAuthKey(type) {
		let userInfo = this.getUserInfo();
		if(!this.validateField(userInfo) && type)
			return userInfo.authKey;
		return config.AUTHORIZE_KEY;
	}

	logout() {
		let confirmMsg = '<div class="alert-information"><p class="m-0">Are you sure want to logout?</p></div>';
		this.confirmation('Logout Confirmation',confirmMsg,'No','Yes') .then(conResp => {
			if(conResp==1) {
			localStorage.removeItem(this.APPSESSION+'_USERID');
			localStorage.removeItem(this.APPSESSION+'_OTP_MOBILE');
			localStorage.removeItem(this.APPSESSION);
			this.navCtrl.navigateRoot('/login');
			}
		});
	}

	replace(string,replaceText,replaceValue) {
		if(!this.validateField(string))
			return string.replace(replaceText,replaceValue);
		return string;
	}

	replaceBulk( str, findArray, replaceAry ){
		let i, regex = [], map = {};
		for( i=0; i<findArray.length;i++) {
				regex.push(findArray[i].replace(/([-[\]{}()*+?.\\^$|#,])/g,'\\$1'));
				map[findArray[i]] = replaceAry[i]; 
			}
			let reMatch = regex.join('|');
			str = str.replace( new RegExp( reMatch,'g'), function(matched){
			return map[matched];
		});
		return str;
  }

  getDeviceInfo() {
	console.log(this.device);
	this.deviceInfo = {model:this.device.model,version:this.device.version,uuid:this.device.uuid,virtual:this.device.isVirtual,platform:this.device.platform,manufacturer:this.device.manufacturer};
    return this.deviceInfo;
  }

	// active hardware back button
  subscribeBackButton() {
		this.hideToast();
		//this.platform.backButton.subscribeWithPriority(-1, () => {
    	//if (!this.routerOutlet.canGoBack()) {
    	//	console.log('close app');
      	//App.exitApp();
    	//}
  	//});
		/*this.unsubscribeBackButton();
		this.backbuttonSubscription = this.platform.backButton.subscribe(async() => {
			if(!this.validateField(localStorage.getItem(this.APPSESSION+'_BACKBUTTON_URL'))) {
				let backUrl;
				backUrl = localStorage.getItem(this.APPSESSION+'_BACKBUTTON_URL')
				localStorage.removeItem(this.APPSESSION+'_BACKBUTTON_URL');
				this.navCtrl.navigateRoot(backUrl);
				return false;
			}
			if(!this.validateField(this.modal)) {
				this.modal.dismiss();
				this.modal = '';
				return false;
			}
			//close modal
			try {
				const element = await this.modalCtrl.getTop();
				if (element) {
			  		element.dismiss();
			  	return false;
				}
		  	} catch (error) {
				console.log(error);
		  	}
		});*/
	}

	unsubscribeBackButton() {
		if(!this.validateField(this.backbuttonSubscription))
			this.backbuttonSubscription && this.backbuttonSubscription.unsubscribe();
	}

	openInAppBrowser(url,target) {
		console.log(url);
		target = (!this.validateField(target))?target:'_self';
		if(this.getPlatform()=='android')
			this.inappOptions.location = 'yes';
		let browser = this.iab.create(url,target,this.inappOptions);
		browser.on('exit').subscribe(() => {
		}, err => {
			console.log(err);
		});
	}

	setPreferences(data) {
		localStorage.setItem(this.APPSESSION+'_PROP_PREFERENCES',JSON.stringify(data));
	}

	getPreferences(type='') {
		let prefData;
		prefData = localStorage.getItem(this.APPSESSION+'_PROP_PREFERENCES');
		if(!this.validateField(prefData)) {
			prefData = JSON.parse(prefData);
			return (this.validateField(type))?prefData:prefData[type];
		}
		return {};
	}

	setSearchedCity(data) {
		localStorage.setItem(this.APPSESSION+'_SEARCHED_CITY',JSON.stringify(data));
	}

	getSearchedCity() {
		let sCity;
		sCity = localStorage.getItem(this.APPSESSION+'_SEARCHED_CITY');
		if(sCity==null) {
			let prefInfo = this.getPreferences('city');
			if(!this.validateField(prefInfo)) {
				return {id:prefInfo.cityUid,name:prefInfo.cityName,cityInfo:prefInfo}
			}
		}
		return JSON.parse(sCity);
	}

	getBhks(type,property) {
		let bhk:any = [];
		let pTypes:any = [];
		let pType = '';
		switch(type) {
			case 'pdp':
			break;
			default:
				let plotType = '';
				for(let listing of property.listings) {
					if(!this.validateField(listing.noOfBedrooms))
						bhk.push(listing.noOfBedrooms.replace('.0',''));
					let property = listing.propertyType;
					if(!this.validateField(property)) {
						if(pTypes.indexOf(property) == -1) {
							pTypes.push(property);
							let pTypeName = this.getPropertyType(property);
							if(property!=6)
								pType += pTypeName+', ';
							else
								plotType = pTypeName;
						}
					}
				}
				pType = (this.validateField(plotType))?pType.slice(0,-2):pType;
				if(bhk.length>0) {
					let sorted = bhk.sort((n1,n2)=> n1 - n2);
					let minBhk = sorted[0];
					let maxBhk = sorted[bhk.length-1];
					let bhks;
					if(!this.validateField(minBhk))
						bhks = minBhk+''+(minBhk < maxBhk?' - '+maxBhk:'')+' BHK ';
					return bhks+pType+plotType;
				}
				return pType+plotType;
			break;
		}
		return '';
	}

	getPropertyPrice(pageType,type,data) {
		let minVal = 0,maxVal = 0;
		switch(type) {
			case 'agreement':
				minVal = data.minAgreementValue;
				maxVal = data.maxAgreementValue;
			break;
			case 'final':
				minVal = data.minFinalPrice;
				maxVal = data.maxFinalPrice;
			break;
			case 'base':
				if(pageType=='pdp') {
					minVal = data.minPrice;
					maxVal = data.maxPrice;
				}
				else {
					minVal = data.propertyPriceMin;
					maxVal = data.propertyPriceMax;
				}
			break;
		}
		return [minVal,maxVal];
	}
	
	getPrice(type,property) {
		let price:any = [];
		let priceParam = '';
		let hidePrice = property.hidePrice;
		let priceVal:number = 0,priceValMax:number = 0;
		let prpPrice;
		if(type=='pdp') {
			this.pdpPropertyDatas.propertyType = [];
			this.pdpPropertyDatas.bhks = [];
			this.pdpPropertyDatas.floor = [];
			let pTypes:any = [];
			/*for (let pType in property.bedroomWiseQuickSummary) {
				let propertyInfo = property.bedroomWiseQuickSummary[pType];
				if(pTypes.indexOf(pType) == -1) {
					pTypes.push(pType);
					this.pdpPropertyDatas.propertyType.push(this.getPropertyType(pType));
				}
				let bedRoomInfo = propertyInfo;
				if(Object.keys(bedRoomInfo).length>0) {
					for(let bhks in bedRoomInfo) {
						if(bhks!='Plot')
							this.pdpPropertyDatas.bhks.push(bhks);
						/* [priceVal,priceValMax] = this.getPropertyPrice(type,priceParam,bedRoomInfo[bhks]);
						if(!this.validateField(priceVal)) price.push(priceVal);
						if(!this.validateField(priceValMax)) price.push(priceValMax); *
					}
				}
				/* else {
					[priceVal,priceValMax] = this.getPropertyPrice(type,priceParam,propertyInfo);
					if(!this.validateField(priceVal)) price.push(priceVal);
					if(!this.validateField(priceValMax)) price.push(priceValMax);
				} *
			}*/
			hidePrice = property.otherDetails.hidePrice;
			let floorData = new Array();
			for (let pData in property.summary) {
				let propertyInfo = property.summary[pData];
				let pType = propertyInfo.propertyType;
				if(pTypes.indexOf(propertyInfo.propertyType) == -1) {
					pTypes.push(pType);
					this.pdpPropertyDatas.propertyType.push(this.getPropertyType(pType));
				}
				let bhks = propertyInfo.bedroom;
				if(this.pdpPropertyDatas.bhks.indexOf(bhks) == -1 && bhks !=null)
					this.pdpPropertyDatas.bhks.push(bhks);
				bhks = (this.validateField(bhks) && pType==6)?-1:bhks;
				if(this.validateField(floorData[bhks]))
					floorData[bhks] = new Array();
				floorData[bhks].push(propertyInfo);
			}
			this.pdpPropertyDatas.floor.push(floorData);
			if(price.length > 0) {
				let sortedPrice = price.sort((n1,n2)=> n1 - n2);
				let minPrice = sortedPrice[0];
				let maxPrice = sortedPrice[sortedPrice.length-1];
				prpPrice = this.formatRupee(minPrice,2);
				prpPrice += (minPrice < maxPrice)?' - '+this.formatRupee(maxPrice,2):'';
			}
			let bhks = this.pdpPropertyDatas.bhks.sort((n1,n2)=> n1 - n2);
			this.pdpPropertyDatas.bhks = bhks;
			this.pdpPropertyDatas.propertyType = this.pdpPropertyDatas.propertyType;
		}

		if(!hidePrice) {
			switch(type) {
				/*case 'hfp':
					let minPrice = !this.validateField(property.propertyPriceMin)?property.propertyPriceMin:'';
					let maxPrice = !this.validateField(property.propertyPriceMax)?property.propertyPriceMax:'';
					return this.formatRupee(minPrice,2)+(!this.validateField(maxPrice)?'+':'');
				break;*/
				case 'pdp':
					return {types:this.pdpPropertyDatas.propertyType,bhks:this.pdpPropertyDatas.bhks,price:prpPrice,floor:this.pdpPropertyDatas.floor};
				break;
				case 'lp':
					if(property.showAgreementValue)
						priceParam = 'agreement';
					else if(property.showFinalPrice)
						priceParam = 'final';
					else
						priceParam = 'base';
					for(let listing of property.listings) {
						/* switch(priceParam) {
							case 'agreement':
								priceVal = listing.minAgreementValue;
							break;
							case 'final':
								priceVal = listing.minFinalPrice;
							break;
							case 'base':
								priceVal = listing.propertyPriceMin;
							break;
						} */
						[priceVal,priceValMax] = this.getPropertyPrice(type,priceParam,listing);
						if(!this.validateField(priceVal))
							price.push(priceVal);
						if(!this.validateField(priceValMax))
							price.push(priceValMax);
					}
					if(price.length > 0) {
						let sorted = price.sort((n1,n2)=> n1 - n2);
						let minPrice = sorted[0];
						let maxPrice = sorted[sorted.length-1];
						return this.formatRupee(minPrice,2)+((minPrice != maxPrice)?(price.length > 1?' - '+this.formatRupee(maxPrice,2):''):'');
					}
				break;
				default:
					if(property.showAgreementValue)
						priceParam = 'agreement';
					else if(property.showFinalPrice)
						priceParam = 'final';
					else
						priceParam = 'base';
					for(let listing of property.listings) {
						/* switch(priceParam) {
							case 'agreement':
								priceVal = listing.minAgreementValue;
							break;
							case 'final':
								priceVal = listing.minFinalPrice;
							break;
							case 'base':
								priceVal = listing.propertyPriceMin;
							break;
						} */
						[priceVal,priceValMax] = this.getPropertyPrice(type,priceParam,listing);
						if(!this.validateField(priceVal))
							price.push(priceVal);
						if(!this.validateField(priceValMax))
							price.push(priceValMax);
					}
					if(price.length > 0) {
						let sorted = price.sort((n1,n2)=> n1 - n2);
						let minPrice = parseInt(sorted[0]);
						let maxPrice = parseInt(sorted[sorted.length-1]);
						
						return this.formatRupee(minPrice,2)+(minPrice!=+maxPrice?'+':'');
					}
				break;
			}
		}
		else {
			return (type=='pdp')?{types:this.pdpPropertyDatas.propertyType,bhks:this.pdpPropertyDatas.bhks,price:'Price on request',floor:this.pdpPropertyDatas.floor}:'Price on request';
		}
	}

	getMetaInfo(type) {
		let metaDatas, metaInfo;
		metaInfo = this.getSession('METAINFO');
		metaDatas = JSON.parse(metaInfo);
		switch(type) {
			case 'propertytype':
				return metaDatas.propertyTypes;
			break;
		}
	}

	getPropertyType(propertyId) {
		let metaInfo = this.getMetaInfo('propertytype');
		let property = metaInfo.find(item => item.id == propertyId);
		return (!this.validateField(property))?property.name:'';
		/*for(let listing of property.listings) {
			if(this.validateField(listing.propertyType)) {
				switch(listing.propertyType) {
					case 1:
						pType += 'Apartment/Flats1474, ';
					break;
					case 2:
						pType += 'Villas/Homes, ';
					break;
					case 3:
						pType += 'Row Houses, ';
					break;
					case 4:
						pType += 'Plot/Land';
					break;
				}
			}
		}*/
	}

	checkShortlisted(pId,shortListed) {
		return shortListed.some(function(entry) {
			/*if (Array.isArray(entry)) {
				return this.checkShortlisted(entry, pId);
			}*/
			return entry.listingIdInt === pId;
		});
	}

	goBack(cPage) {
		let prevUrl:any;
		prevUrl = this.getSession('PREVURL')
		if(!this.validateField(prevUrl))
			this.navCtrl.navigateRoot(prevUrl);
	}

	checkInternet() {
		this.network.onDisconnect().subscribe(() => {
			console.log('network was disconnected :-(');
		});
		this.network.onConnect().subscribe(() => {
			console.log('network connected!');
			console.log(this.network);
			setTimeout(() => {
				if (this.network.type === 'wifi') {
					console.log('we got a wifi connection, woohoo!');
				}
			}, 3000);
		});
		window.addEventListener('offline', () => {
			this.redirect('/no-network');
			return 'offline';
		});
		if(!navigator.onLine)
			this.redirect('/no-network');
	}

	redirect(page) {
		if(!this.validateField(page))
			this.navCtrl.navigateRoot(page);
	}

	getPlatform() {
		let platform = this.device.platform;
		return !this.validateField(platform)?platform.toLowerCase():'android';
	}
	
	getVersion() {
		return config.VERSION[this.getPlatform()];
	}

	getPlatformUrl() {
		return config.URL[this.getPlatform()];
	}

	getDefaultCountry() {
		return 91;
	}

	getPossessionDate(type,data) {
		if(type==1) {
			//let bhks = this.getBhks('hfp',data);
			if(data.possessionDate < Date.now())
				return 'Ready';
			else
				return this.getFormattedDate(3,data.possessionDate);
		}
		if(type==2) {
			let bhks = this.getBhks('srp',data);
			if(data.possessionDate < Date.now())
				return (data.propertyType==6 || data.propertyType=='Plots' || bhks=='Plot')?'Ready to construct':'Ready to move';
			else
				return this.getFormattedDate(2,data.possessionDate);
		}
		else {
			if(data.possessionStatus=='Ready to move')
				return (data.propertyType==6 || data.propertyType=='Plots')?'Ready to construct':data.possessionStatus;
			else if(data.possessionDate < Date.now())
				return (data.propertyType==6 || data.propertyType=='Plots')?'Ready to construct':'Ready to move';
			else
				return this.getFormattedDate(2,data.possessionDate);
		}
	}

	getBhkText(bhk) {
		if(this.validateField(bhk)) return '';
		return (bhk==0.5)?'1 RK':bhk+' BHK';
	}

	selOpened:boolean = false;
	selParam: any;
	setSelectable(param,type) {
		this.selOpened = type;
		this.selParam = param;
	}

	checkSelectable() {
		if(this.selOpened) {
			this.selOpened = false;
			this.selParam.close();
		}
		//document.querySelectorAll('.cities-modal').forEach(el => el.remove());
	}

	subscribeTopic(newTopic,oldTopic) {
		if(newTopic!='')
			this.fcm.subscribe(newTopic).then(data => { console.log(data); });
		if(oldTopic!='')
			this.fcm.unsubscribe(oldTopic).then(data => { console.log(data); });

	}

	getImageNotFound() {
		return 'https://roofandfloor.thehindu.com/static/images/common/no_image_available.png';
	}
}