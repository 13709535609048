import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes, CanActivate } from '@angular/router';
import { AuthGuard } from '../providers/guards/AuthGuard';
import { LoginGuard } from '../providers/guards/LoginGuard';
import { DataResolverService } from '../providers/services/data-resolver.service';

const routes: Routes = [
  { path: '', canActivate: [LoginGuard], data: { authGuardRedirect: 'login' }, pathMatch: 'full',loadChildren: () => import('./login/login.module').then(m => m.LoginPageModule) },
  { path: 'login', canActivate: [LoginGuard], loadChildren: () => import('./login/login.module').then(m => m.LoginPageModule)},
  { path: 'home', canActivate: [AuthGuard], loadChildren: () => import('./home/home.module').then(m => m.HomeModule)},
  /*{
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginPageModule)
  },*/
  {
    path: 'update-account',
    loadChildren: () => import('./update-account/update-account.module').then(m => m.UpdateAccountModule)
  },
  {
    path: 'preferences/:isLogin',
    loadChildren: () => import('./preferences/preferences.module').then(m => m.PreferencesModule)
  },
  {
    path: 'preferences',
    loadChildren: () => import('./preferences/preferences.module').then(m => m.PreferencesModule)
  },
  /*{
    path: 'home',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
  },*/
  {
    path: 'shortlists',
    loadChildren: () => import('./shortlists/shortlists.module').then( m => m.ShortlistsModule)
  },
  {
    path: 'my-profile',
    loadChildren: () => import('./my-profile/my-profile.module').then( m => m.MyProfileModule)
  },
  {
    path: 'update-profile',
    loadChildren: () => import('./update-profile/update-profile.module').then( m => m.UpdateProfileModule)
  },
  {
    path: 'property-search',
    loadChildren: () => import('./property-search/property-search.module').then( m => m.PropertySearchModule)
  },
  {
    path: 'property-lists',
    loadChildren: () => import('./property-lists/property-lists.module').then( m => m.PropertyListsModule)
  },
  {
    path: 'property-details',
    loadChildren: () => import('./property-details/property-details.module').then( m => m.PropertyDetailsModule)
  },
  /* {
    path: 'property-details/:pId',
    loadChildren: () => import('./property-details/property-details.module').then( m => m.PropertyDetailsModule)
  }, */
  {
    path: 'property-details/:id',
    resolve: {
      id: DataResolverService
    },
    loadChildren: () => import('./property-details/property-details.module').then( m => m.PropertyDetailsModule)
  },
  {
    path: 'properties-contacted',
    loadChildren: () => import('./properties-contacted/properties-contacted.module').then( m => m.PropertiesContactedModule)
  },
  {
    path: 'splashs',
    loadChildren: () => import('./splashs/splashs.module').then(m => m.SplashsPageModule)
  },
  {
    path: 'otp',
    loadChildren: () => import('./otp/otp.module').then(m => m.OtpModule)
  },
  {
    path: 'offers',
    loadChildren: () => import('./offers/offers.module').then( m => m.OffersModule)
  },
  {
    path: 'no-network',
    loadChildren: () => import('./no-network/no-network.module').then( m => m.NoNetworkModule)
  },
  {
    path: 'version-update',
    loadChildren: () => import('./version-update/version-update.module').then( m => m.VersionUpdateModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
