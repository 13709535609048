import { Injectable } from '@angular/core';
import { ApiService } from '../rest-api/api.service';
import { CommonProvider } from './common.provider';

@Injectable({
  providedIn: 'root'
})
export class CommonService{

	public APPSESSION = 'ROOFANDFLOOR';
	private loading:any;
	private toast:any;
	private modal;
	private backInit;
	public shortMonths;
	public fullMonths;
	pdpPropertyDatas:any = [];

	constructor(public common: CommonProvider,public api: ApiService) {
	}

	addShortlist(data): Promise<any> {
		return new Promise(async (resolve) => {
			let lDtls = {listingId:data.listingIdInt};
			await this.api.addShortlist(data).subscribe(res => {
				if(res.code=200) {
					resolve(res);
				} else {
					resolve([]);
				}
			}, err => {
				resolve([]);
				this.common.showAlert('Error','SERVER_ERROR')
			});
		});
	}

	contactBuilder(data): Promise<any> {
		return new Promise(async (resolve) => {
			let lDtls = {listingId:data.listingIdInt};
			let deviceInfo = this.common.device;
			if(!this.common.validateField(deviceInfo)) {
				this.common.getDeviceInfo();
				deviceInfo = this.common.deviceInfo;
			}
			if(this.common.validateField(deviceInfo.platform)) {
				deviceInfo.platform = 'ios';
				deviceInfo.version = '15.2';
			}
			let platform = deviceInfo.platform.toLowerCase(); 
			await this.api.contactBuilder({contactType:data.contactType+platform,listingDetails:lDtls,userAgent:deviceInfo.platform+' - '+deviceInfo.version,userSource:platform,responseSource:platform}).subscribe(res => {
				if(res.code=200) {
					resolve(res);
				} else {
					resolve(res);
				}
			}, err => {
				switch(err.error) {
					/* case 401:
						this.common.showAlert('Error',err.message)
					break; */
					default:
						this.common.showAlert('Error','SERVER_ERROR')
				}
				resolve([]);
			});
		});
	}

	getAreaLabel(data,floor) {
		switch(data.areaType.toLowerCase()) {
			case 'sba':
				return 'Super Builtup Area';
			break;
			case 'ba':
				return 'Builtup Area';
			break;
			case 'ca': case 'carpet_area':
				return 'Carpet Area';
			break;
			case 'la':
				return 'Land Area';
			break;
			case 'sba,la': case 'la,sba':
				return (floor.propertyType==6)?'Land Area':'Super Builtup Area';
			break;
			case 'ba,la': case 'la,ba':
				return (floor.propertyType==6)?'Land Area':'Builtup Area';
			break;
			case 'ca,la': case 'la,ca':
				return (floor.propertyType==6)?'Land Area':' Carpet Area';
			break;
		}
	}

	getTexts(type,data) {
		switch(type) {
			case 1:
				return (data.bedroom > 1)?'Bedrooms':'Bedroom';
			break;
			case 2:
				return (data.bathrooms > 1)?'Bathrooms':'Bathroom';
			break;
		}
	}

	getTruePrice(data): Promise<any> {
		return new Promise(async (resolve) => {
			let lDtls = {listingId:data.listingIdInt};
			await this.api.getTruePrice(data.id).subscribe(res => {
				if(res.status=='success') {
				  let pData = this.getTruePriceData(data,res.data.data);
				  resolve({code:200,data:pData,content:res.data});
				}
				else {
					this.common.showAlert('Error','SERVER_ERROR')
					resolve([]);
				}
			  }, err => {
				this.common.showAlert('Error','SERVER_ERROR')
				resolve([]);
			});
		});
	}

	getTruePriceData(data,priceData) {
		let areaVal = '';
		let tPData:any = [];
		switch(data.areaType) {
			case 'SBA':
				areaVal = 'superBuiltUpArea';
			break;
			case 'BuiltUp':
				areaVal = 'builtUpArea';
			break;
			case 'Carpet':
				areaVal = 'carpetArea';
			break;
			case 'LA':
				areaVal = 'landArea';
			break;
		}
		for(let tData of priceData) {
			let areaData:any = []
			areaData['id'] = tData.listingUnitSummaryAreaUid;
			areaData['area'] = tData[areaVal];
			let pType = tData.listingUnitSummaryPropertyTypeId;
			if(tPData[pType]==undefined)
				tPData[pType] = [];
			let bhks = tData.listingUnitSummaryBedrooms;
			bhks = (Math.floor(bhks) == bhks)?parseInt(bhks):bhks;

			if(tPData[pType][bhks]==undefined)
				tPData[pType][bhks] = [];
			tPData[pType][bhks].push(areaData);
		}
		console.log(tPData);
		return tPData;
	}

	getFinalPrice(id): Promise<any> {
		return new Promise(async (resolve) => {
			await this.api.getFinalPrice(id).subscribe(res => {
				resolve(res);
				/* if(res.status=='success') {
				  let pData = this.getTruePriceData(data,res.data.data);
				  resolve({code:200,data:pData});
				}
				else {
					this.common.showAlert('Error','SERVER_ERROR');
					resolve([]);
				} */
			  }, err => {
				this.common.showAlert('Error','SERVER_ERROR');
				resolve([]);
			});
		});
	}

	priceLabel(data) {
		let lData = data.listingInfo;
		if(lData.showAgreementValue==1)
			return 'Agreement';
		else if(lData.showFinalPrice==1)
			return 'Final';
		else
			return 'Base';
	}

	finalPrice(data) {
		let lData = data.content.listingInfo;
		let prices = data.prices;
		if(lData.showAgreementValue==1)
			return this.common.formatRupee(prices.agreementValue,3);
		else if(lData.showFinalPrice==1)
			return this.common.formatRupee(prices.finalPrice,3);
		else
			return this.common.formatRupee(prices.basePrice,3);
	}

	getBreakupTtl(data,type) {
		let ttl = 0;
		let priceBreakup = data.prices.priceBreakup;
		if(type==1)
			ttl = parseFloat(priceBreakup.parking) + parseFloat(priceBreakup.otherCharges);
		else if(type==2)
			ttl = parseFloat(priceBreakup.registration) + parseFloat(priceBreakup.gst);
		return this.common.formatRupee(ttl,3);
	}

	getAreas(type,data) {
		return data.areaMin+(data.areaMin < data.areaMax?' - '+data.areaMax:'')+' '+data.measuringUnit;
	}
}