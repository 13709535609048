import { NgModule,Injector } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { NgxEchartsModule } from 'ngx-echarts';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { IonicSelectableModule } from 'ionic-selectable';
//import { SplashScreen } from '@ionic-native/splash-screen/ngx';
//import { SplashScreen } from '@capacitor/splash-screen';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { SecurityTokenInterceptor } from '../providers/rest-api/interceptor';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
//import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { Device } from '@awesome-cordova-plugins/device/ngx';
import { Network } from '@ionic-native/network/ngx';
import { FirebaseX } from '@awesome-cordova-plugins/firebase-x/ngx';
import { LocalNotifications} from '@ionic-native/local-notifications/ngx'

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [BrowserModule, IonicModule.forRoot(), NgxEchartsModule.forRoot({echarts: () => import('echarts'),}),AppRoutingModule,HttpClientModule,IonicSelectableModule, BrowserAnimationsModule],
  providers: [
    Network,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {provide: HTTP_INTERCEPTORS,useClass: SecurityTokenInterceptor,multi: true},
    StatusBar,
    InAppBrowser,
    SocialSharing,
    Device,
    FirebaseX,
	LocalNotifications
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  static injector: Injector;
  constructor(injector: Injector) {
    AppModule.injector = injector;
  }
}
