  import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { NavController, Platform } from '@ionic/angular';
import { NavigationExtras } from '@angular/router';
import { IonicSelectableComponent } from 'ionic-selectable';
import { FormControl } from "@angular/forms";
import { debounceTime } from "rxjs/operators";

import { CommonProvider } from '../../providers/common/common.provider';
import { ApiService } from '../../providers/rest-api/api.service';
import { CommonService } from '../../providers/common/common.service';

class Cities {
  public cityUid: number;
  public cityName: string;
  public cityState: string;
}

@Component({
  selector: 'app-home',
  templateUrl: './home.html',
  styleUrls: ['./home.scss'],
})

export class Home implements OnInit {
  cities: Cities[];
  city: Cities;
  prefCity:any;
  prefInfo:any = [];
  homeContent:any = [];
  shortListed:any = [];
  public loggedInfo;
  public showCities = false;
  public prefCityVal:string;
  public searchControl: FormControl;
  @ViewChild('citiesList') cityList: IonicSelectableComponent;
  //@Output() loggedEvent = new EventEmitter<string>();

  constructor(public navCtrl: NavController, public common: CommonProvider,public api: ApiService,public service:CommonService) {
    this.loggedInfo = this.common.getUserInfo();
    //this.loggedEvent.emit(this.loggedInfo)
    this.getCities();
    this.homeContent.banner = {premiumHpMobileBannerImage:'https://roofandfloor.thehindu.com/static/images/pages/home/home_banner-opti.jpg',url:'',default:1};
    this.searchControl = new FormControl();
  }

  ngOnInit() {
    this.searchControl.valueChanges.pipe(debounceTime(1000)).subscribe(search => {
      if(search.length>=3)
        this.initHomeSearch(search);
    });
  }

  ionViewWillEnter() {
    this.searchControl.setValue('');
    /* this.prefInfo = this.common.getPreferences();
    if(!this.common.validateField(this.prefInfo) && !this.common.validateField(this.prefInfo.city)) {
      let prefCity = this.prefInfo.city;
      this.prefCityVal = prefCity.cityName;
      this.prefCity = {cityUid:prefCity.cityUid,cityName:this.prefCityVal};
    } */
    let searchedInfo = this.common.getSearchedCity();
    if(!this.common.validateField(searchedInfo)) {
      this.prefCityVal = searchedInfo.name;
      this.prefCity = {cityUid:searchedInfo.id,cityName:searchedInfo.name};
    }
    else {
      this.prefInfo = {city:''};
      this.prefCityVal = '';
    }
    this.getShortlisted();
    if(!this.common.validateField(this.prefCityVal))
      this.getHomeContents();
  }

  ionViewWillLeave() {
    this.common.checkSelectable();
  }

  initHomeSearch(search) {
    let params: NavigationExtras = {
      queryParams: {
        searched: search
      }
    };
    this.navCtrl.navigateForward(['property-search'], params);
  }

  async getCities() {
    await this.api.getCities({}).subscribe(res => {
      if(res.code==200) {
        this.homeContent.cities = res.response;
      } else {
      }
    }, err => {
    });
  }

  getHomeContents() {
    this.homeContent.banner = {premiumHpMobileBannerImage:'https://roofandfloor.thehindu.com/static/images/pages/home/home_banner-opti.jpg',url:'',default:1};
    this.getTopBanner();
    this.getHomeContent();
    this.getFeaturedBuilders();
  }

  async getTopBanner() {
    await this.api.getBanner({type:'homepage',city:this.prefCityVal}).subscribe(res => {
      if(res.code=200) {
        let respData = res.response;
        if(!this.common.validateField(respData)) {
          let [first] = Object.keys(respData);
          if(!this.common.validateField(respData[first]))
            this.homeContent.banner = respData[first];
        }
      }
    }, err => {
    });
  }

  selectCity(event: {
    component: IonicSelectableComponent,
    value: any
  }) {
	  this.prefCityVal = event.value.cityName;
    this.prefInfo.city = event.value;
    //this.common.setPreferences(this.prefInfo);
    //this.common.subscribeTopic(event.value.cityName,this.prefCityVal);
    this.common.setSearchedCity({name:this.prefCityVal,id:this.prefInfo.city.cityUid,cityInfo:this.prefInfo.city});
    this.getHomeContents();
  }
  iconSet='ant-design:heart-outlined';
  async getHomeContent() {
    this.common.showLoader();
    this.homeContent.featuredProjects = [];
    this.homeContent.topPicksProjects = [];
    this.homeContent.projectGallery = [];
    this.homeContent.showcaseProjects = [];
    await this.api.getHomeContent({type:'homepage',city:this.prefCityVal}).subscribe(res => {
      this.common.hideLoader();
      if(res.code=200) {
        let respContent = res.message;
        if(!this.common.validateField(respContent)) {
          this.homeContent.featuredProjects = respContent.featuredProjects.length>0?respContent.featuredProjects:'';
          this.homeContent.topPicksProjects = respContent.topPicksProjects.length>0?respContent.topPicksProjects:'';
          this.homeContent.projectGallery = respContent.showcaseProjects.length>0?respContent.showcaseProjects:'';
          this.homeContent.showcaseProjects = respContent.hpTextProjects.length>0?respContent.hpTextProjects:'';
        }
      } else {
        this.common.showAlert('Error','SERVER_ERROR')
      }
    }, err => {
      this.common.hideLoader();
    });
  }

  async getFeaturedBuilders() {
    await this.api.getFeaturedBuilders({type:'homepage',city:this.prefCityVal,limit:6}).subscribe(res => {
      if(res.code=200) {
        let respContent = res.message;
        this.homeContent.featuredBuilders = (!this.common.validateField(respContent) && respContent.length>0)?respContent:[];
      } else {
      }
    }, err => {
    });
  }

  getPropertyContent(type,data) {
    return `
      <div class="property-image">
        <a href="javascript:;" routerLink="/property-details"><img src="${data.propertyImageReference}"></a>
        <span class="premium-label" *ngIf="${data.packageDisplayName!=''}">${data.packageDisplayName}</span>
        <a href="javascript:;" class="favourite active"><span class="iconify" data-icon="akar-icons:heart"></span></a>
      </div>
      <div class="property-content">
          <h4><a href="javascript:void(0);" routerLink="/property-details">${data.listingName}</a></h4>
          <p><span class="iconify" data-icon="clarity:map-marker-line"></span>${data.locality}</p>
          <p class="dark-text mb-0">`+this.common.getBhks('hfp',data)+` BHK Apartments/Flats</p>
          <p class="property-price">`+this.common.getPrice('hfp',data)+`</p>
        </div>`;
    /* return '<div class="property-image"> \
          <a href="javascript:;" routerLink="/property-details"><img src="'+fp.propertyImageReference+'"></a> \
          <span class="premium-label" *ngIf="'+fp.propertyImageReference+!=\'\'">'+fp.packageDisplayName+'</span> \
          <a href="javascript:;" class="favourite active"><span class="iconify" data-icon="akar-icons:heart"></span></a> \
        </div> \
        <div class="property-content"> \
          <h4><a href="javascript:void(0);" routerLink="/property-details">{{fp.listingName}}</a></h4> \
          <p><span class="iconify" data-icon="clarity:map-marker-line"></span> {{fp.locality}}</p> \
          <p class="dark-text mb-0">{{common.getBhks('hfp',fp)}} BHK Apartments/Flats</p> \
          <p class="property-price">{{common.getPrice('hfp',fp)}}</p> \
        </div>'; */
  }

  async getShortlisted() {
    await this.api.getShortlists({limit:200,offset:0}).subscribe(res => {
      if(res.code=200) {
        let respContent = res.response;
        this.shortListed = (!this.common.validateField(respContent.listings.message))?respContent.listings.message.result:[];
      } else {
      }
    });
  }

  async addShortlist(id,value) {
    //this.iconvalue=false;
    let shIndex = this.shortListed.map(function(o) { return o.listingIdInt; }).indexOf(id);
    let isDelete = false;
    if(shIndex > -1) {
      this.shortListed.splice(shIndex,1);
      isDelete = true;
    }
    this.common.showLoader();
    await this.api.addShortlist({id:id,isDelete:isDelete}).subscribe(res => {
      this.common.hideLoader();
      if(res.code=200) {
        let respContent = res.message;
        if(shIndex <= -1)
          this.shortListed.push({listingIdInt:id});
      } else {
      }
    }, err => {
      this.common.hideLoader();
    });
  }


  callFn(type) {
    switch(type) {
      case 'comingsoon':
        this.common.showAlert('Coming Soon','This feature will be available soon');
      break;
      case 'logout':
        let confirmMsg = '<div class="alert-information"><p class="m-0">Are you sure want to logout?</p></div>';
        this.common.confirmation('Logout Confirmation',confirmMsg,'No','Yes') .then(conResp => {
          if(conResp==1) {
            localStorage.removeItem(this.common.APPSESSION+'_USERID');
            localStorage.removeItem(this.common.APPSESSION+'_OTP_MOBILE');
            localStorage.removeItem(this.common.APPSESSION);
            this.navCtrl.navigateRoot('/login');
          }
        });
      break;
    }
  }

  async searchBuilder(type,builder) {
    let qParams:any = {};
    if(type=='builder')
      qParams.builder = builder;
    let params: NavigationExtras = {
      queryParams: qParams
    };
    this.common.setSession('PREVURL','/');
    this.navCtrl.navigateForward(['property-lists'], params);
  }

  cityListOpened:boolean = false;
  checkCityList(type) {
    if(this.cityListOpened) {
      /* if(this.cityList.isOpened)
        this.cityList.close(); */
    }
    this.cityListOpened = false;
    this.common.setSelectable('',this.cityListOpened);
  }

  cityListTrigger(type) {
    this.cityListOpened = type;
    this.common.setSelectable(this.cityList,this.cityListOpened);
  }
}