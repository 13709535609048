import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { CommonProvider } from '../../providers/common/common.provider';

@Injectable()
export class SecurityTokenInterceptor implements HttpInterceptor {
	constructor(public common: CommonProvider) { }
	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		let userAuth = ['/user/','/contact','/userupdate','/final_price'];
		const isAuthorizeUrl = request.url.includes('/user/') || request.url.includes('/contact') || request.url.includes('/userupdate');
		let authKey = this.common.getAuthKey(isAuthorizeUrl);
		request = request.clone({
			setHeaders: {
				'Content-Type': 'application/json',
				'Authorization': authKey,
			}
		});
		return next.handle(request);
	}
}