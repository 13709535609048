import { Component, ViewChild, AfterViewInit  } from '@angular/core';
import { Platform, NavController, AlertController, ToastController,  ActionSheetController, PopoverController, IonRouterOutlet, ModalController, MenuController } from '@ionic/angular';

//import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { SplashScreen } from '@capacitor/splash-screen';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
//import { FCM } from '@awesome-cordova-plugins/fcm/ngx';
//import { FCM } from "cordova-plugin-fcm-with-dependecy-updated/ionic";
import { FirebaseX } from '@awesome-cordova-plugins/firebase-x/ngx';
import { LocalNotifications} from '@ionic-native/local-notifications/ngx'

import { CommonProvider } from '../providers/common/common.provider';
import { ApiService } from '../providers/rest-api/api.service';
import { Home } from "./home/home";

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements AfterViewInit {
  @ViewChild(Home) child;
  loggedInfo:[];

  text: string='RoofAndFloor'
  //imgurl:string= 'https://roofandfloor.thehindu.com/static/images/common/logo_white.svg'
  imgurl:string = '';
  link: string='https://bit.ly/3AsF0KR'
  shareModal:boolean = false;

  public appPages = [
    { title: 'My Profile', url: '/my-profile',method:''},
    { title: 'My Preferences', url: '/preferences',method:''},
    { title: 'My Shortlists', url: '/shortlists',method:''},
    { title: 'Contacted Properties', url: '/properties-contacted',method:''},
    /*{ title: 'My Settings', url: 'javascript:;',method:'comingsoon'},*/
    { title: 'Notifications', url: 'javascript:;',method:'comingsoon'},
    { title: 'About Us', url: 'javascript:;',method:'about'},
    { title: 'Contact Us', url: 'javascript:;',method:'contact'},
    { title: 'Terms of Use', url: 'javascript:;',method:'terms'},
    { title: 'Privacy Policy', url: 'javascript:;',method:'privacy'},
    { title: 'Realty Guide', url: 'javascript:;',method:'real-estate-blog'},
    { title: 'Rate Us', url: 'javascript:;',method:'rate-us'},
    { title: 'Share Our APP', url: 'javascript:;',method:'share-app'},
    { title: 'Logout', url: 'javascript:;',method:'logout'},
  ];

  networkConnection:any;

  constructor(
    private platform: Platform,
    private statusBar: StatusBar,
    private navCtrl: NavController,
    private common: CommonProvider,
    private api: ApiService,
    private socialSharing: SocialSharing,
    private fcm: FirebaseX,
	public localnotify: LocalNotifications,
  ) {
    this.loggedInfo = this.common.getUserInfo();
    this.initializeApp();
  }
  ngAfterViewInit() {
    this.loggedInfo = this.common.getUserInfo();
  }

  receiveMessage($event) {
    console.log($event);
  }

  initializeApp() {
    this.common.checkInternet();
    /*if(this.common.validateField(this.loggedInfo))
      this.navCtrl.navigateRoot('/login');
    else
      this.navCtrl.navigateRoot('/home');*/

    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      // let status bar overlay webview
      this.statusBar.overlaysWebView(false);
      // set status bar to white
      this.statusBar.backgroundColorByHexString('#1F4C6B');
      this.statusBar.styleLightContent();
      setTimeout(() => { SplashScreen.hide(); },800);
      this.common.getDeviceInfo();

      /* this.fcm.getToken().then(token => {
        console.log(token);
      }); */

      this.fcm.getToken().then(token => console.log(`The token is ${token}`)).catch(error => console.error('Error getting token', error));

      /* this.fcm.onMessageReceived()
      .subscribe(data => console.log(`User opened a notification ${data}`)); */

      this.fcm.onMessageReceived().subscribe(data => {
        console.log(`User opened a notification ${data}`)
        console.log(data);
        if(data.wasTapped){
          console.log("Received in background");
          alert(JSON.stringify(data))
        } else {
          if(data.body!='' && data.body!=undefined) {
            this.localnotify.schedule({
              title: data.title,
              text: data.body,
              data: data,
              //image: data.image,
              attachments:[data.image],
            });
            console.log("Received in foreground");
          }
        }
      });

      this.localnotify.on('click').subscribe(data => {
        console.log('click on notification fired');
        console.log(data);
      });

      this.fcm.hasPermission().then(hasPermission => {
        if (hasPermission) {
          console.log("Has permission!");
        }
      })

      this.fcm.onTokenRefresh().subscribe((token: string) => console.log(`Got a new token ${token}`));

      this.common.checkInternet();
      //this.common.subscribeBackButton();
    });
	  this.getMetaData();
  }

  callFn(type) {
    switch(type) {
      case 'comingsoon':
        this.common.showAlert('Coming Soon','This feature will be available soon');
      break;
      case 'about':
      case 'contact':
      case 'terms':
      case 'privacy':
      case 'real-estate-blog':
        this.common.openInAppBrowser('https://roofandfloor.thehindu.com/'+type,'_blank');
      break;
      /* case 'terms-of-use':
        this.common.openInAppBrowser('https://roofandfloor.thehindu.com/terms','_self');
      break;
      case 'privacy-policy':
        this.common.openInAppBrowser('https://roofandfloor.thehindu.com/privacy','_self');
      break; */
      case 'share-app':
        //this.common.openInAppBrowser('https://web.whatsapp.com/send?text=I have found lot of properties in this app<br>https://bit.ly/3AsF0KR','_system');
        //this.shareModal = true;
        //this.common.openInAppBrowser('mailto:&subject=Having lot of properties in ONE APP&body=I can see lot of properties in one APP. Install it https://bit.ly/3AsF0KR','_system');
        this.ShareLink('');
      break;
      /* case 'blog':
        this.common.openInAppBrowser('https://roofandfloor.thehindu.com/real-estate-blog','_self');
      break; */
      case 'rate-us':
        this.common.openInAppBrowser(this.common.getPlatformUrl(),'_system');
      break;
      case 'logout':
        this.common.logout();
      break;
    }
  }

  async getMetaData() {
    await this.api.getVersion().subscribe(res => {
      if(res.status==200) {
        if(res.resource[this.common.getPlatform()].version != this.common.getVersion()) {
          this.common.redirect('/version-update');
        }
      }
    }, err => {
    });
    await this.api.getMetaData({}).subscribe(res => {
      if(res.code==200) {
        this.common.setSession('METAINFO',JSON.stringify(res.response));
        //this.homeContent.cities = res.response;
      } else {
      }
    }, err => {
    });
  }

  ShareLink(parameter){
    //const url = this.link
    //const text = parameter+'\n'
    this.socialSharing.share(this.text+' All properties under one place: '+this.link,'RoofAndFloor Application','','')
  }
  
  ShareWhatsapp(){
    this.socialSharing.shareViaWhatsApp(this.text, this.imgurl, this.link).then(() => {
    }).catch(() => {
      // Sharing via email is not possible
      this.common.showAlert('Error','Unable to share via Whatsapp');
    });
  }

  ShareFacebook(){
    this.socialSharing.shareViaFacebookWithPasteMessageHint(this.text, this.imgurl, this.link, this.text).then(() => {
    }).catch(() => {
      this.common.showAlert('Error','Unable to share via Facebook');
    });
  }

  SendEmail(){
    this.socialSharing.shareViaEmail('RoofAndFloor Application<br>'+this.link,this.text, [''])
  }

  SendTwitter(){
    this.socialSharing.shareViaTwitter(this.text, this.imgurl, this.link).then(() => {
    }).catch(() => {
      this.common.showAlert('Error','Unable to share via Twitter');
    });
  }

  SendInstagram(){
    this.socialSharing.shareViaInstagram(this.text, this.imgurl).then(() => {
    }).catch(() => {
      this.common.showAlert('Error','Unable to share via Instagram');
    });
  }

  shareModalDismiss(event: Event) {
    this.closeShareModal(0);
  }

  closeShareModal(type) {
    this.shareModal = type;
  }
}