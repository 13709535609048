let config = {
	'VERSION':{'android':'0.0.1','ios':'0.0.1'},
	'SITE_NAME': 'Roof and Floor',
	/*'SERVER_URL': 'https://qa.roofandfloor.com/',
	'SERVER_URL_V3': 'https://qa.roofandfloor.com/ksl-api/',*/
	'SERVER_URL': 'https://api.roofandfloor.com/',
	'SERVER_URL_V3': 'https://new-api.roofandfloor.com/ksl-api/',
	//'AUTHORIZE_KEY': '52b1b16c-fac9-4253-bfb5-c05015289b94',
	'AUTHORIZE_KEY': '14f24e43-be63-4340-bbe3-62a993bb3a94',
	'PREF_PROPERTY_TYPES': {1:'Apartments/Flats',2:'Row Houses',3:'Villas/Homes',4:'Plots/Land'},
	'PREF_BHKS' : {1:'1 BHK',2:'2 BHK',3:'3 BHK'},
	'PREF_PRICE_RANGE' : {1:'< 50 Lakhs',2:'50 - 80 Lakhs',3:'80 Lakhs - 1 Cr.',4:'1 Crore+'},
	'PREF_POSSESSION' : {1:'Ready to move',2:'Within 1 Year',3:'Within 1-2 Years',4:'Above 2 Years'},
	'SERVER_ERROR': 'Service is not available now, please try after sometime',
	'URL':{'android':'https://bit.ly/3AsF0KR','ios':''}
};
export default config;